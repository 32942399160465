import * as React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

export const indexPageQuery = graphql`
  {
    allContentfulDocument(limit: 20) {
      nodes {
        title
        document {
          file {
            url
          }
        }
      }
    }
    allContentfulPost(sort: { fields: publishDate, order: DESC }, limit: 5) {
      nodes {
        title
        slug
        description {
          description
        }
        publishDate(formatString: "DD.MM.YYYY")
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const documents = data.allContentfulDocument.nodes
  const posts = data.allContentfulPost.nodes

  return (
    <Layout>
      <div className='slider-wrap'>
        <StaticImage src='../images/hero.png' width={1024} placeholder='blurred' alt='Bytové družstvo Pod Kempou' />
      </div>
      <div className='highlight'>
        <div className='container'>
          <div className='sixteen columns'>
            <div className='highlight-inner'>
              <div className='col-text'>
                <h2 className='headline'>
                  <strong>Bytové družstvo Pod Kempou, zapsané u Krajského soudu Ostrava, Dr. 1749</strong>
                </h2>
                <p>
                  <strong>Sídlo:</strong> Okružní 1756/13, Český Těšín
                  <strong style={{ paddingLeft: '20px' }}>IČO:</strong> 26830256
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container main'>
        <div className='callout'>
          <div className='callout-inner'>
            <div className='col-text'></div>
          </div>
        </div>

        <div className='nine columns'>
          <div className='row'>
            <h3 className='title'>
              <span>Poslední příspěvky</span>
            </h3>
            {posts.map((post, index) => (
              <article className='post recent' key={index}>
                <div className='post-header'>
                  <div className='post-title'>
                    <h4 className='title-text'>
                      <a href={`/post/${post.slug}`}>{post.title}</a>
                    </h4>
                    <ul className='post-meta'>
                      <li>
                        <i className='icon-history'></i> {post.publishDate}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='post-body'>
                  <p>{post.description?.description}</p>
                  <a className='post-read' href={`/post/${post.slug}`}>
                    Čtěte dále →
                  </a>
                </div>
              </article>
            ))}
          </div>
        </div>

        <div className='seven columns'>
          <div className='row'>
            <h3 className='title'>
              <span>Dokumenty ke stažení</span>
            </h3>

            <ul className='arrow-list' style={{ textDecoration: 'underline' }}>
              {documents.map((document, index) => (
                <li key={index}>
                  <a href={document?.document?.file?.url}>{document.title}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>{' '}
    </Layout>
  )
}

export default IndexPage
